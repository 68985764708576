<template>

<div class="mb-20">
  <h3>Sign In To Admin</h3>
  <p class="opacity-60 font-weight-bold">Enter your details to login to your account:</p>
</div>

<form @submit.prevent="SendLogin()" class="form">

  <div class="form-group">
    <input class="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 rounded-pill border-0 py-4 px-8 mb-5" type="text" placeholder="Email" name="username" autocomplete="off" v-model="form.email"/>
  </div>

  <div class="form-group">
    <input class="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 rounded-pill border-0 py-4 px-8 mb-5" type="password" placeholder="Password" name="password" v-model="form.password"/>
  </div>

  <div class="form-group d-flex flex-wrap justify-content-between align-items-center px-8">
    <div class="checkbox-inline">
      <label class="checkbox checkbox-outline checkbox-white text-white m-0">
        <input type="checkbox" name="remember" />
        <span></span>Remember me </label>
    </div>
    <router-link to="/forgot" id="kt_login_signup" class="text-white font-weight-bold" tag="a">
      Forget Password ?
    </router-link>
  </div>

  <div class="form-group text-center mt-10">
    <button class="btn btn-pill btn-outline-white font-weight-bold opacity-90 px-15 py-3">Sign In</button>
  </div>

</form>

<div class="mt-10">
  <span class="opacity-70 mr-4">Don't have an account yet?</span>
    <router-link to="/signup" class="text-white font-weight-bold" tag="a">
      Sign Up
    </router-link>
</div>

<Loading :active="loading" size="xl" :full="true"/>
</template>

<script>
/* Utilidades y ayudantes */
import { mapActions } from 'vuex'

/* Componentes */
import Loading from '@components/Loading.vue'

/* Servicios de Api */
import Api from '@services/modules/authenticated.js';

export default {
  name: 'LoginAdmin',
  components:{
    Loading
  },
  data(){
    return {
      loading:false, //Mostrar el cargando.
      form: { //Credenciales de inicio de session.
        email: "admin@admin.com",
        password: "admin",
      },
    }
  },
  methods: {
    /* Llamamos a N cantidad de acciones del store */
    ...mapActions('auth', ['SaveSession']),

    /*Funcion de login*/
    SendLogin() {
      /*Activamos el cargador al usuario en pantalla*/
      this.loading = true

      let startLogin = (data) => {
        this.SaveSession(data) //Iniciamos id_session.
        setTimeout( // Esperar
          () => {
            this.loading = false //desactivamos el cargador.
            location.reload() //Recargamos la pagina.
          },
          500,
        )
      }

      Api.login( //Hacemos la peticion con las credenciales.
        this.form, //Credenciales email y password.
        (data, validate) => { //Obtenemos la informacion devuelta y un validador.
          if(validate) { //Validamos que no este vacia la data.
            startLogin(data)
          }
        }
      )
    }

  }
}
</script>
